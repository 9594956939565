@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"




























































.empty-list
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

  .img-empty-result
    margin: 30px 0

  .empty-desc
    font-size: 16px
    line-height: 28px
    margin-top: 5px
    color: #666E75
    padding: 0 8px
    text-align: center

    &.large-margin-top
      margin-top: 30px

    +mq-m--mf
      font-size: 20px

    .highlight
      font-size: inherit
      font-weight: bold

  .actions-container-buttons
    width: 100%
    flex-direction: column-reverse
    margin-top: 35px

    +mq-m--mf
      margin-top: 95px

    button
      margin: 0

      &:first-child
        margin-top: 20px

    +mq-m--mf
      width: 530px

  .v-step
    top: -40px !important
