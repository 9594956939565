@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"

















































.actions-container-buttons
  display: flex
  padding: 0 16px

  .second-button
    display: none

    +mq-m--mf
      display: flex

  .tools-new
    padding-left: 24px
    padding-right: 24px
    margin-left: 16px
    display: none

    +mq-m--mf
      display: flex

  +mq-m--mf
    padding: 0

.empty-list button
  display: flex !important

